<template lang="pug">
    div.table-responsive
        table.table.table-bordered.table-hover.table-striped.table-checkable.table-highlight-head.mb-4
            thead
                tr
                    th {{ $t('comp.codeassigcom')}} 
                    th {{ $t('comp.codeassigcom1')}} 
                    th {{ $t('comp.codeassigcom2')}} 
                    th.text-center {{ $t('comp.codeassigcom3')}} 
            tbody
                tr(v-for="lot in lotsList")
                    td 
                        p.mb-0 {{lot.idLote}} 
                    td {{ lot.sComments }}
                    td {{ lot.dCreatDate }}
                    td.text-center
                        ul.table-controls
                            li
                                a(href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Descargar Archivo" @click="exportLot(lot.idLote)")
                                    svg.feather.feather-edit-2.text-success(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                        path(d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z")
</template>
<script>
/* eslint-disable */
export default {
    name: 'AssignedLotsComponent',
    data() {
        return {
            lotsList: [],
            idCenter: 0
        }
    },
    methods: {
        async getData()
        {
            await axios.get(apiURL + 'Center/getCodesLots?idAdministrator=' + localStorage.CertIdP + '&idCenter=' + this.idCenter, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.lotsList = response.data.lotsList
            } )
            .catch( (error)  => {
                console.log(error)
            } )
        },        
        async exportLot(idLot)
        {
            this.openLoading(this.$t('msg.adminSwal1aa'));
            await axios.get(apiURL + 'Center/downloadCodesLot?idLot=' + idLot, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.sErrors.length > 0)
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                
                let a = document.createElement("a")
                a.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data.base64CodesFile
                a.download = response.data.sFileName
                document.body.appendChild(a)
                a.click()
                a.remove()

                this.getData()
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        }
    },
    mounted() {
        
        Fire.$on('Test', (params) => {
            this.idCenter = params.idCenter
            this.getData()
        })
    }
}
</script>