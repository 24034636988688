<template lang="pug">
    div.table-responsive
        table.table.table-bordered.table-hover.table-striped.table-checkable.table-highlight-head.mb-4
            thead
                tr
                    th {{ $t('comp.codusecod')}} 
                    th {{ $t('comp.codusecod1')}} 
                    th {{ $t('comp.codusecod2')}} 
                    th {{ $t('comp.codusecod3')}} 
                    th {{ $t('comp.codusecod4')}} 
                    th {{ $t('comp.codusecod5')}} 
                    th {{ $t('comp.codusecod6')}} 
                    th {{ $t('comp.codusecod7')}} 
                    th {{ $t('comp.codusecod8')}} 
            tbody
                tr(v-for="result in userResults")
                    td {{ result.sName }}
                    td {{ result.sEmail }}
                    td {{ result.sAccessCode }}
                    td {{ result.sExamName }}
                    td {{ result.sExamStatus }}
                    td {{ result.iScore }}
                    td {{ result.dPresentationDate }}
                    td {{ result.sResult }}
                    td {{ result.iOportunities }}
</template>
<script>
/* eslint-disable */
export default {
    name: 'UsedCodesComponent',
    data() {
        return {
            idCenter: 0,
            userResults: []
        }
    },
    methods: {
        async getData()
        {
            await axios.get(apiURL + 'Center/getUsersResults?idAdministrator=' + localStorage.CertIdP + '&idCenter=' + this.idCenter, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.userResults = response.data.userResults
            } )
            .catch( (error)  => {
                console.log(error)
            } )
        }      
    },
    mounted() {
        
        Fire.$on('Test', (params) => {
            this.idCenter = params.idCenter
            this.getData()
        })
    }
}
</script>