<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{ $t('codesstore.codestore')}}

                    div
                       nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                                li.breadcrumb-item
                                    router-link(to="/pages/home") Mi Dashboard
                                li.breadcrumb-item.active(aria-current="page") {{ $t('codesstore.codestore')}}

                div.row
                    div.col-xl-4.col-md-4.col-sm-12.col-12
                        div
                            select.col-12.mx-auto(v-model="idSelectedCenter" @change="fireEvent")
                                option(:value=" MyCenter.idCenter" v-for="MyCenter in MyCenters") {{ MyCenter.sCenterName }}
                
                div.row.pb-5
                    div.col-lg-12.col-12.layout-spacing(id="tabsWithIcons")
                        div.statbox.widget.box.box-shadow
                            div.widget-header
                                div.mx-auto.text-center
                                    h4 {{ $t('codesstore.code')}}

                            div.widget-content.widget-content-area.rounded-pills-icon
                                ul.nav.nav-pills.mb-4.mt-3.justify-content-center(id="rounded-pills-icon-tab" role="tablist")
                                    li.nav-item.ml-2.mr-2
                                        a.nav-link.mb-2.active.text-center(data-toggle="pill" href="#disponibles" role="tab" aria-controls="disponibles" aria-selected="true")
                                            svg.feather.feather-circle(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                                circle(cx="12" cy="12" r="10")
                                            div {{ $t('codesstore.assign')}}

                                    li.nav-item.ml-2.mr-2
                                        a.nav-link.mb-2.text-center(data-toggle="pill" href="#asignados" role="tab" aria-controls="asignados" aria-selected="false" @click="fireEvent")
                                            svg.feather.feather-user-check(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                                path(d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2")
                                                circle(cx="8.5" cy="7" r="4")
                                                polyline(points="17 11 19 13 23 9")
                                            template {{ $t('codesstore.assigned')}}

                                    li.nav-item.ml-2.mr-2
                                        a.nav-link.mb-2.text-center(data-toggle="pill" href="#usados" role="tab" aria-controls="usados" aria-selected="false")
                                            svg.feather.feather-check-circle(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                                path(d="M22 11.08V12a10 10 0 1 1-5.93-9.14")
                                                polyline(points="22 4 12 14.01 9 11.01")
                                            template {{ $t('codesstore.used')}}



                                    li.nav-item.ml-2.mr-2
                                        a.nav-link.mb-2.text-center(data-toggle="pill" href="#cancelados" role="tab" aria-controls="cancelados" aria-selected="false" :class="{disabled: true}")
                                            svg.feather.feather-x-circle(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                                circle(cx="12" cy="12" r="10")
                                                line(x1="15" y1="9" x2="9" y2="15")
                                                line(x1="9" y1="9" x2="15" y2="15")
                                            template {{ $t('codesstore.canceled')}}


                                    
                            div.tab-content
                                div.tab-pane.fade.show.active(id="disponibles" role="tabpanel" aria-labelledby="disponibles")
                                    available-codes-component()

                                div.tab-pane.fade(id="usados" role="tabpanel" aria-labelledby="usados")
                                    used-codes-component()
                                             
                                div.tab-pane.fade(id="asignados" role="tabpanel" aria-labelledby="asignados")
                                    assigned-lots-component()
                                                
                                div.tab-pane.fade(id="cancelados" role="tabpanel" aria-labelledby="cancelados")
                                    h1 template {{ $t('codesstore.cancelcod')}}
           

</template>
<script>
/* eslint-disable */
import AvailableCodesComponent from '@/components/CodesStore/AvailableCodesComponent'
import AssignedLotsComponent from '@/components/CodesStore/AssignedLotsComponent'
import UsedCodesComponent from '@/components/CodesStore/UsedCodesComponent'
export default {
    name: 'CodeStore',
    components: {
        AvailableCodesComponent, AssignedLotsComponent, UsedCodesComponent
    },    
    data() {
        return {
            formstate: {},
            MyCenters: [],
            idSelectedCenter: 0
        }
    },
    methods: {
        async fireEvent()
        {
            //Fire.$emit('Test')
            Fire.$emit('Test', {
                idCenter: this.idSelectedCenter
            })
        }
    },
    mounted() {
        this.checkPermisions(this.$router.currentRoute.path)
        this.fireEvent()
    },
    beforeMount() {
        this.MyCenters = JSON.parse(localStorage.getItem("CertIdCent"));
        this.idSelectedCenter = this.MyCenters[0].idCenter
    }
        
}
</script>    