<template lang="pug">

    div.row.mt-5.pb-5.d-flex.align-items-center
        div.col-lg-6.col-md-6.layout-spacing.mx-auto
            div.statbox.widget.box.box-shadow
                div.widget-header
                    div.row
                        div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                h4 {{ $t('comp.codavaila')}}   
                    vue-form(:state="formstate")
                        div.widget-content.widget-content-area

                            div.form-group.mb-4
                                label() {{ $t('comp.codavaila1')}}   
                                input.form-control(type="text" :value="model.iAvailableCodes"  readonly) 

                            div.form-group.mb-4
                                label(for="iNumber") {{ $t('comp.codavaila2')}}   
                                validate(tag="div")
                                    input.form-control(id="iNumber" name="iNumber" :min="1" required type="number" :placeholder="$t('comp.codavaila2')" v-model="model.iAssignCodes" )
                                    field-messages.text-danger(name="iNumber" )
                                        div(slot="required") {{ $t('comp.codavaila3')}}   
                                        div(slot="min") {{ $t('comp.codavaila4')}}   

                            div.form-group.mb-4
                                label(for="sComments") {{ $t('comp.codavaila5')}}   
                                validate(tag="div")
                                    input.form-control(type="text" id="sComments" required name="sComments" placeholder="sComments" v-model="model.sComments")
                                    field-messages.text-danger(name="sComments" )
                                        div(slot="required") {{ $t('comp.codavaila6')}}   
                            
                            button.btn.btn-secondary(type="button" @click="saveAssign") {{ $t('btns.btnsend')}}                                        


</template>
<script>
/* eslint-disable */
export default {
    name: 'AvailableCodesComponent',
    data() {
        return {
            formstate: {},
            model: {
                iAvailableCodes: 0,
                iAssignCodes: 0,
                sComments: ''
            },
            modelSaveAssign:{
                idCenter: 0,
                idAdministrator: 0,
                sComments: '',
                iNumberToAssign: 0
            },
            idCenter: 0
        }
    },
    methods: {
        async getData()
        {
            await axios.get(apiURL + 'Center/getCodesResume?idCenter=' + this.idCenter, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.model.iAvailableCodes = response.data.iAvailableNumber
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },                
        async saveAssign() {
            if(this.formstate.$invalid) {
                return;
            }

            if(this.model.iAssignCodes > this.model.iAvailableCodes)
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: this.$t('msg.textinv'),
                    footer: ''
                })
                return
            }

            this.openLoading(this.$t('msg.datasave'));
            this.modelSaveAssign.idCenter = this.idCenter
            this.modelSaveAssign.idAdministrator = localStorage.CertIdP
            this.modelSaveAssign.sComments = this.model.sComments
            this.modelSaveAssign.iNumberToAssign = this.model.iAssignCodes
            

            await axios.post(apiURL + 'Center/assignCodes', this.modelSaveAssign, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.sErrors.length > 0)
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                
                let a = document.createElement("a")
                a.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data.base64CodesFile
                a.download = response.data.sFileName
                document.body.appendChild(a)
                a.click()
                a.remove()

                this.getData()
                this.model.iAssignCodes = 0
                this.model.sComments = ''


            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        },
       
    },
    mounted() {
        Fire.$on('Test', (params) => {
            this.idCenter = params.idCenter
            this.getData()
        })
    }    
        
}
</script>









